import { BaseEntity } from './BaseEntity';

export enum PlanInitPeriodUnit {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year'
}

export enum PlanRecurringPeriodUnit {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year'
}

export enum PlanDownloadable {
  YES = 1,
  NO = 0
}

export enum PlanShow {
  YES = 1,
  NO = 0
}

export enum PlanBest {
  YES = 1,
  NO = 0
}

export interface Plan extends BaseEntity {
  name: string
  title: string
  initPeriodCount: number
  initPeriodUnit: PlanInitPeriodUnit
  initOriginalPrice: number
  initDiscountPrice: number
  recurringPeriodCount: number
  recurringPeriodUnit: PlanRecurringPeriodUnit
  recurringOriginalPrice: number
  recurringDiscountPrice: number
  channelName: string
  subscriptionCount: number
  show: PlanShow
  isBest: PlanBest
  sort: number
  segpayId: string
  epochId: string
  epochTiId: string
  natsId: number
  xsaleId: string
  downloadable: PlanDownloadable
}

export type PartialPlan = Partial<Plan>;
